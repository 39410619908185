html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
a,
button,
section,
header,
head,
tr,
td,
tbody,
table,
input,
textarea,
footer,
blockquote,
code,
hr,
hgroup,
iframe,
img,
label,
li,
legend,
menu,
menuitem,
nav,
ol,
option,
rect,
select,
svg,
tfoot,
th,
title,
ul {
  box-sizing: border-box;
  font-family: "Open Sans";
}

a,
button,
p,
span,
div,
html,
body,
input,
textarea,
label,
li,
ol,
option,
select,
code,
ul {
  font-size: 16px;
}

button {
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
}

button:disabled {
  color: inherit;
}
